<!-- 商品详情 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <ykc-detail :titles="['基础信息']" :className="'all'">
      <div slot="cvBody">
        <div class="form-detail">
          <ykc-form :label-position="'left'" label-width="90px">
            <ykc-detail-item label="*商品名称：">
              <span>{{ detail.skuName || '——' }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="*商品编号：">
              <span>{{ detail.skuNo || '——' }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="*商品类别：">
              <span>{{ detail.skuTypeMean || '——' }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="*初始库存:">
              <span>{{ detail.stock || '——' }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="*商品简介:">
              <span>{{ detail.skuDesc || '——' }}</span>
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
    <ykc-detail :titles="['售卖信息']" :className="'all'" style="margin-top: 20px">
      <div slot="cvBody">
        <div class="form-detail">
          <ykc-form :label-position="'left'" label-width="100px">
            <ykc-detail-item label="*商品标价：">
              <span>{{ detail.score || '——' }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="*商品现价">
              <span>{{ detail.scoreDiscount || '——' }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="*上架选择">
              <span>{{ detail.saleTypeMean || '——' }}</span>
            </ykc-detail-item>
            <ykc-detail-item label="*上架时间">
              <span>{{ detail.saleDate || '——' }}</span>
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
    <ykc-detail :titles="['商品明细']" :className="'all'" style="margin-top: 20px">
      <div slot="cvBody">
        <div class="form-detail">
          <ykc-form :label-position="'left'" label-width="100px">
            <ykc-detail-item label="*商品图片：">
              <img
                v-for="(item, index) in detail.imgs"
                :key="index"
                @click="$showPic({ picUrls: [item] })"
                :src="item" />
            </ykc-detail-item>
            <ykc-detail-item label="*详细介绍:">
              <ykc-editor ref="editor" :disabled="true"></ykc-editor>
            </ykc-detail-item>
          </ykc-form>
        </div>
      </div>
    </ykc-detail>
    <div class="onlineConent" style="margin-top: 12px">
      <ykc-detail :isHead="false" :tips="false" :className="'all'">
        <div slot="cvBody">
          <ykc-tabs
            :tabList="tabList"
            :active="activeName"
            :rightHeaderNoMarginTop="true"
            :showHeaderButtons="false">
            <div slot="first">
              <ykc-table ref="YkcTable" :data="priceTableData" :columns="priceTableColumns">
                <ykc-pagination
                  align="center"
                  slot="pagination"
                  ref="YkcPagination1"
                  :total="pageInfo1.total"
                  :pageSize.sync="pageInfo1.size"
                  :currentPage.sync="pageInfo1.current"
                  @size-change="handlePriceTabSizeChange"
                  @current-change="handlePriceTabCurrentChange" />
              </ykc-table>
            </div>
            <div slot="second">
              <ykc-table ref="YkcTable" :data="stockTableData" :columns="stockTableColumns">
                <ykc-pagination
                  align="center"
                  slot="pagination"
                  ref="YkcPagination2"
                  :total="pageInfo2.total"
                  :pageSize.sync="pageInfo2.size"
                  :currentPage.sync="pageInfo2.current"
                  @size-change="handleStockTabSizeChange"
                  @current-change="handleStockTabCurrentChange" />
              </ykc-table>
            </div>
          </ykc-tabs>
        </div>
      </ykc-detail>
    </div>
  </scroll-layout>
</template>

<script>
  import { pointsMall } from '@/service/apis';

  export default {
    data() {
      return {
        name: 'commodityDetail',
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        skuImgs: [],

        detail: {}, // 详情数据
        // 表格列
        tableColumns: [
          {
            label: '卡券名称',
            prop: 'rewardName',
            width: 240,
          },
          { label: '发送数量(张/人)', prop: 'rewardCount', width: 240 },
        ],
        tabList: [
          {
            label: '定价记录',
            name: 'first',
          },
          {
            label: '库存变更记录',
            name: 'second',
          },
        ],
        priceTableData: [], // 定价记录数据
        priceTableColumns: [
          { label: '时间', prop: 'modifiedTime' },
          { label: '操作账号', prop: 'modifiedName' },
          { label: '变后标价', prop: 'oldScore' },
          { label: '变后现价', prop: 'oldScoreDiscount' },
        ],
        stockTableData: [], // 库存变更记录
        stockTableColumns: [
          { label: '时间', prop: 'modifiedTime' },
          { label: '操作账号', prop: 'modifiedName' },
          { label: '变动库存', prop: 'changeStock' },
        ],
        activeName: 'first',
        pageInfo1: {
          total: 0,
          current: 1,
          size: 10,
        },
        pageInfo2: {
          total: 0,
          current: 1,
          size: 10,
        },
      };
    },
    computed: {},
    created() {
      this.getDetail();
      this.getPrice();
      this.getStock();
    },
    methods: {
      /**
       *获取商品详情
       */
      getDetail() {
        const reqParams = this.$route.query;
        console.log('reqParams', reqParams);
        pointsMall.skuDetail(reqParams).then(res => {
          console.log('detail', res);
          this.detail = res || {};
          this.$refs.editor.setHtml(res.content || '——');
          // this.getPrice();
          // this.getStock();
        });
      },
      /**
       * 获取价格列表
       */
      getPrice() {
        const reqParams = this.$route.query;
        console.log('reqParams', reqParams);
        pointsMall
          .queryPriceForPage({
            ...reqParams,
            ...this.pageInfo1,
          })
          .then(res => {
            console.log('queryPriceForPage', res);
            this.priceTableData = res.records || [];
            this.pageInfo1.total = res.total || 0;
          });
      },
      /**
       * 获取库存列表
       */
      getStock() {
        const reqParams = this.$route.query;
        console.log('reqParams', reqParams);
        pointsMall
          .queryStockForPage({
            ...reqParams,
            ...this.pageInfo2,
          })
          .then(res => {
            console.log('queryStockForPage', res);
            this.stockTableData = res.records || [];
            this.pageInfo2.total = res.total || 0;
          });
      },
      handlePriceTabSizeChange(size) {
        this.pageInfo1.size = size;
        this.getPrice();
      },
      handlePriceTabCurrentChange(currentPage) {
        this.pageInfo1.current = currentPage;
        this.getPrice();
      },
      handleStockTabSizeChange(size) {
        console.log('size', size);
        this.pageInfo2.size = size;
        this.getStock();
      },
      handleStockTabCurrentChange(currentPage) {
        this.pageInfo2.current = currentPage;
        this.getStock();
      },
    },
  };
</script>
<style scoped>
  .ykc-detail-item-box .el-form-item .el-form-item__content img {
    width: 100px;
    height: 100px;
    border: 6px solid#DAD8D8;
    margin-right: 10px;
  }
</style>
